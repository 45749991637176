// import alpine from 'alpinejs'
import Alpine from 'alpinejs'
import 'htmx.org'
import Lenis from '@studio-freight/lenis'
import Swiper from 'swiper/bundle'
import simpleParallax from 'simple-parallax-js';


// Object.assign(window, {Alpine: alpine}).Alpine.start()
window.Alpine = Alpine

import.meta.webpackHot?.accept(console.error);

//

window.accordionItem = (index) => {
    return {
        index: index,
        h: 0,
        init: function() {
            // Wait to be able to get the original height
            this.$nextTick(() => {
                this.h = this.$refs.content.scrollHeight
            })
        },
        onResize: function() {
            const original = this.current
            this.current = this.index
            this.h = this.$refs.content.scrollHeight
            this.current = original
        },
    }
}

Alpine.start()

//

/*document.body.addEventListener('htmx:load', function () {
    console.log('htmx load')
    // htmx.logAll()
})*/


/*****************************************************************/
/***********************  Page transition  ***********************/
/*****************************************************************/

document.addEventListener('DOMContentLoaded', () => {
	const page_transition = document.getElementById('page-transition');
	const transition_links = document.querySelectorAll('a:not([target="_blank"]):not(.didomi-preferences)');

	/* Arrivée sur une page */
	setTimeout(() => {
		page_transition.classList.remove('is-active');
	}, 400);

	/* Départ d'une page */
	transition_links.forEach(link => {
		link.addEventListener('click', e => {
			e.preventDefault();
			page_transition.classList.add('is-active');

			let target = link.href ? link.href : '/';
			setTimeout(() => {
				window.location.href = target;
			}, 400);
		});
	});
});


/*****************************************************************/
/**********************  Lenis  **********************************/
/*****************************************************************/

const lenis = new Lenis();

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);



var assayTables = document.querySelectorAll('.my-desktop-assay-table');

assayTables.forEach((assayTable) => {
    assayTable.addEventListener('wheel', (e) => {
        e.stopPropagation();
    });
});


/*****************************************************************/
/**********************  Simple parallax   ***********************/
/*****************************************************************/

const images_parallax = document.querySelectorAll('.img-parallax');
new simpleParallax(images_parallax, {
    delay: 1.8,
    transition: "cubic-bezier(0,0,0,1)",
    orientation: "up",
    scale: 1.05,
});

const event = new Event('resize');
window.dispatchEvent(event);

/*****************************************************************/
/************************  Target blank  *************************/
/*****************************************************************/

function addTargetBlank(){
    const allExternalLinks = document.querySelectorAll("a[href^='http']:not([href^='"+window.location.protocol+"//"+window.location.hostname+"']):not(.not-external)");
    
    allExternalLinks.forEach(function(link){
        link.setAttribute("target", "_blank");
    });
}
addTargetBlank();

/*****************************************************************/
/********************  Détection du mobile  **********************/
/*****************************************************************/

(function detect_mobile(){
    const htmlElement = document.querySelector('html');
    if (/Android|iPhone/i.test(navigator.userAgent)) {
        // Mobile
        htmlElement.classList.add('mobile');
        htmlElement.classList.remove('no-mobile');
    } else {
        // Desktop
        htmlElement.classList.remove('mobile');
        htmlElement.classList.add('no-mobile');
    }
    window.addEventListener('resize', detect_mobile);
})();


/*****************************************************************/
/*******************  Détection du navigateur  *******************/
/*****************************************************************/

(function detect_navigator(){
    const userAgent = navigator.userAgent.toLowerCase();
    const htmlElement = document.querySelector('html');

    switch (true) {
        case /edge/i.test(userAgent):
            htmlElement.classList.add('edge');
            break;
        case /chrome/i.test(userAgent):
            htmlElement.classList.add('chrome');
            break;
        case /firefox/i.test(userAgent):
            htmlElement.classList.add('firefox');
            break;
        case /safari/i.test(userAgent):
            htmlElement.classList.add('safari');
            break;
        default:
            htmlElement.classList.add('other-navigator');
            break;
    }
})();

/*****************************************************************/
/**************  Typographie : évite les orphelins  **************/
/*****************************************************************/

(function spacesHandle() {
    const nbspSelectors = document.querySelectorAll("h1, h2, h3, h4, h5, h6, p");
  
    nbspSelectors.forEach(function (element) {
  
        if(!element.classList.contains('allow-orphan')){

            // Retire les espaces au début et à la fin de la chaîne de caractères
            element.innerHTML = element.innerHTML.trim();
            
            // Ajoute un espace insécable (\u00A0) avant les points d'exclamation, d'interrogation,
            // deux-points et points-virgule
            element.innerHTML = element.innerHTML.replace(/ ([!?:;])/g, "\u00A0$1");

            // Ajoute un espace insécable avant le dernier mot si les deux derniers mots comportent moins de 12 caractères
            /*const words = element.textContent.split(" ");
            const lastWord = words[words.length - 1];
            const beforeLastWord = words[words.length - 2];
            const numberOfLetters_lastWord = (lastWord === undefined) ? 0 : lastWord.length;
            const numberOfLetters_beforeLastWord = (beforeLastWord === undefined) ? 0 : beforeLastWord.length;
            
            if ((numberOfLetters_lastWord < 12 ) && (numberOfLetters_lastWord + numberOfLetters_beforeLastWord < 24)) {
                element.innerHTML = element.innerHTML.replace(/\s(?=[^ ]*$)/, "\u00A0");
            }*/

        }

    });
})();


/*****************************************************************/
/**************************  Burger menu  ************************/
/*****************************************************************/

(function my_burger_menu(){

    const burger_menu = document.getElementById('burger-menu');
    const burger_menu_open = document.getElementById('burger-menu-open');

    if(document.body.contains(burger_menu) && document.body.contains(burger_menu_open)){
        
        // Initialisation
        document.body.classList.remove('overflow-hidden');
        burger_menu.classList.remove('active');
        burger_menu_open.classList.remove('open');
        burger_menu_open.classList.add('hidden');

        // Gestion du clic
        burger_menu.addEventListener('click', function() {
            
            // Ouverture du menu
            if(!burger_menu.classList.contains('active')){

                // Gestion du background
                document.body.classList.add('overflow-hidden');

                // Gestion du burger
                burger_menu.classList.add('active');
                burger_menu_open.classList.remove('hidden');
                setTimeout(function() {
                    burger_menu_open.classList.add('open');
                }, 1);


            // Fermeture du menu
            } else {

                // Gestion du background
                document.body.classList.remove('overflow-hidden');

                // Gestion du burger
                burger_menu.classList.remove('active');
                burger_menu_open.classList.remove('open');
                setTimeout(function() {
                    burger_menu_open.classList.add('hidden');
                }, 400);
            } 
        });
    }
})();


/*****************************************************************/
/**********************  Menu sticky  ****************************/
/*****************************************************************/


function scrollFunction() {
    var desktop_menu = document.getElementById('desktop-menu');

    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50 ) {
        desktop_menu.classList.add('scroll-version');
    } else {
        desktop_menu.classList.remove('scroll-version');
    }
}
scrollFunction()
window.onscroll = function() {
    scrollFunction();
};

/*****************************************************************/
/************  Menu sticky if logged into back-office ************/
/*****************************************************************/

(function menuStickyLogged() {
    document.addEventListener('DOMContentLoaded', function() {
        var wpAdminBar = document.getElementById('wpadminbar');
        if (wpAdminBar) {
            var wpAdminBarHeight = getComputedStyle(wpAdminBar).height;
            var mainHeader = document.getElementById('main-header');
            if (mainHeader) {
                mainHeader.style.marginTop = wpAdminBarHeight;
            }
        }
    });
    window.addEventListener('resize', menuStickyLogged);
})();


/*****************************************************************/
/**********************  Block Contact  **************************/
/*****************************************************************/

(function contactIsOnTop() {
    var mainElement = document.getElementById("main");
    var mainHeader = document.getElementById("main-header");

    if (mainElement && mainHeader) {
        var firstChild = mainElement.firstElementChild;

        if (firstChild && firstChild.classList.contains("block-form-contact")) {
            firstChild.classList.add("on-top-of-page");
            firstChild.style.marginTop = getComputedStyle(mainHeader).height;
        }
    }

    window.addEventListener('resize', contactIsOnTop);
})();

/*****************************************************************/
/**********************  Block Content steps  ********************/
/*****************************************************************/

(function scrollFunction() {
    var sticky_images = document.querySelectorAll('.img-sticky');
    var desktop_menu = document.getElementById('main-header');
    var desktopMenuHeight = desktop_menu.offsetHeight;
    var customPadding = 75; 
    var myTop = desktopMenuHeight + customPadding;
    sticky_images.forEach(function(img) {
        img.style.top = myTop + 'px';
    });
})();

/*****************************************************************/
/**************************  Block Stats  ************************/
/*****************************************************************/

(function my_swiper_function() {
    const my_blocks = document.querySelectorAll('.block-stats');
    my_blocks.forEach(my_block => {
        const swiperElement = my_block.querySelector('.my-stat-swiper');
        var mySwiper = new Swiper(swiperElement, {
            loop: true,
            slidesPerView: 2,
            spaceBetween: 15,
            pagination: {
                el: ".swiper-stat-pagination",
                clickable: true,
            },
            //autoHeight: true,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 25,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                1400: {
                    slidesPerView: 4,
                    spaceBetween: 80,
                },
            },
        });

        var customBtnPrev = my_block.querySelector(".custom-btn-prev");
        if (customBtnPrev) {
            customBtnPrev.addEventListener("click", function () {
                mySwiper.slidePrev();
            });
        }

        var customBtnNext = my_block.querySelector(".custom-btn-next");
        if (customBtnNext) {
            customBtnNext.addEventListener("click", function () {
                mySwiper.slideNext();
            });
        }
    });
})();

/*****************************************************************/
/**********************  Block team list  ************************/
/*****************************************************************/


(function teamMembertPopup() {
    var teamMemberContainers = document.querySelectorAll('.my-team-member-container');
    var appElement = document.getElementById('app'); 
    if (teamMemberContainers && appElement) {
        teamMemberContainers.forEach(function (member) {
            var teamMember = member.querySelector('.my-team-member');
            var popupContent = member.querySelector('.popup-content');
            var close = member.querySelector('.my-team-member-close-popup');
            if(teamMember && popupContent && close){
                teamMember.addEventListener('click', function () {
                    appElement.classList.add("blacked");
                    document.body.style.overflow = 'hidden';
                    //lenis.stop();
                    //popupContent.classList.add("active");
                });
                close.addEventListener('click', function () {
                    appElement.classList.remove("blacked");
                    //lenis.start();
                    document.body.style.overflow = '';
                    //popupContent.classList.remove("active");
                }); 

                popupContent.addEventListener('wheel', (e) => {
                    e.stopPropagation();
                });

                /* Gestion du clic en dehors de popupContent */
                /*appElement.addEventListener('click', function (event) {
                    if (popupContent.classList.contains('active') && !popupContent.contains(event.target)) {
                        appElement.classList.remove("blacked");
                        popupContent.classList.remove("active");
                    }
                });*/
            }
        });
    }
})();

/*****************************************************************/
/**********************  Block Content columns  ******************/
/*****************************************************************/

/*function setupEqualHeight() {
    function equalizeHeight(elements) {
        let maxHeight = 0;
    
        // Parcourez les éléments pour trouver la hauteur maximale
        elements.forEach(element => {
            const elementHeight = element.offsetHeight;
            maxHeight = Math.max(maxHeight, elementHeight);
        });
    
        // Appliquez la hauteur maximale à tous les éléments
        elements.forEach(element => {
            element.style.height = `${maxHeight}px`;
        });
    }
  
    const my_block = document.querySelector('.block-content-columns');
    if (my_block) {
        const titles = my_block.querySelectorAll('.my-title');
        const subtitles = my_block.querySelectorAll('.my-subtitle');
        equalizeHeight(titles);
        equalizeHeight(subtitles);
    }
}
  
// Appelez la fonction au chargement de la page
setupEqualHeight();

// Ajoutez un écouteur d'événements resize
window.addEventListener('resize', setupEqualHeight);*/
  


/*****************************************************************/
/*************************  Adjust 404 height  *******************/
/*****************************************************************/

(function adjustSitemapHeight(){
    document.addEventListener('DOMContentLoaded', function() {
        var my_404_section = document.getElementById('my-404-section');
        var my_404_section_child = document.getElementById('my-404-section-child');
        if(my_404_section && my_404_section_child){
            var offsetHeight = document.getElementById('footer').offsetHeight;
            my_404_section.style.minHeight = 'calc(100vh - ' + offsetHeight + 'px)';
            my_404_section_child.style.minHeight = 'calc(100vh - ' + offsetHeight + 'px)';
        }
    });
    window.addEventListener('resize', adjustSitemapHeight);
})();